<template>
  <div class="edit-form_wrapper">
    <single-select
      id="instructions"
      v-model="formData.instructions"
      label="Instructions:"
      :options="instructionsOptions"
      placeholder="Select an option..."
      :danger="!!errors.instructions"
      :danger-message="errors.instructions"
    >
    </single-select>
    <div class="form-group">
      <div class="form-check">
        <input
          id="contacted"
          v-model="formData.contacted"
          class="form-check-input"
          type="checkbox"
        />
        <label class="form-check-label" for="contacted">Contacted</label>
      </div>
    </div>
    <div
      class="form-group"
      :class="[{ 'has-danger': !!batchCommentErrors.comment }]"
    >
      <label id="multiComment-label" for="multiComment" aria-label="Comment">
        Comment:
      </label>
      <div class="input-group">
        <textarea
          id="multiComment"
          v-model="formData.comment"
          class="form-control"
          :class="[
            {
              'form-control-danger': !!batchCommentErrors.comment,
            },
          ]"
          aria-describedby="multiComment-label"
          data-cy="notesPurchaseForm"
        />
        <div v-if="!!batchCommentErrors.comment" class="form-control-feedback">
          {{ batchCommentErrors.comment }}
        </div>
      </div>
    </div>
    <alert
      id="multipleEditWarning"
      type="warning"
      message="You are editing multiple checks"
    ></alert>
    <div class="d-flex justify-content-end align-items-center">
      <icon-button
        id="multipleEditCancel"
        icon="x"
        label="Cancel"
        type="accent"
        outline
        @click="close"
      />
      <icon-button
        id="multipleEditSave"
        icon="save"
        label="Save"
        type="primary"
        :disabled="disableSave"
        @click="submit"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { instructionsOptions } from "@/utils/constants"
import { IconButton, Alert, SingleSelect } from "@/components/elements"

export default {
  name: "FormMultiEdit",
  components: {
    IconButton,
    Alert,
    SingleSelect,
  },
  props: {
    modChecks: {
      type: String,
      required: true,
    },
    batchCommentErrors: {
      type: Object,
      required: true,
    },
    disableSave: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      instructionsOptions,
      formData: {
        to_modify: this.modChecks,
        instructions: undefined,
        contacted: undefined,
        comment: undefined,
      },
    }
  },
  computed: {
    ...mapGetters("checks", ["errors"]),
  },
  methods: {
    close() {
      this.$emit("close")
    },
    submit() {
      this.$emit("submit", this.formData)
    },
  },
}
</script>

<style lang="postcss" scoped>
.edit-form__wrapper {
  display: flex;
  flex: auto;
  flex-direction: column;
}

#multipleEditSave {
  margin-left: 0.5em;
}
</style>
