<template v-slot:default>
  <div class="row">
    <input-box
      id="payeeNumber"
      v-model="activeCheck.payee_number"
      class="col-md-6"
      type="text"
      label="Payee Number:"
      placeholder="XXXXXXX"
      :danger="!!errors.payee_number"
      :danger-message="errors.payee_number"
      data-cy="payee-number"
    >
    </input-box>
    <input-box
      id="payeeName"
      v-model="activeCheck.payee_name"
      class="col-md-6"
      type="text"
      label="Payee Name:"
      placeholder="Wilbur Wildcat"
      :danger="!!errors.payee_name"
      :danger-message="errors.payee_name"
      data-cy="payee-name"
    >
    </input-box>
    <single-select
      id="checkIdentifier"
      v-model="activeCheck.check_identifier"
      class="col-md-6"
      label="Check Identifier:"
      :options="checkIdOptions"
      placeholder="Select an option..."
      :danger="!!errors.check_identifier"
      :danger-message="errors.check_identifier"
      data-cy="check-identifier"
    >
    </single-select>
    <input-box
      id="edocNum"
      v-model="activeCheck.edoc_number"
      v-maska="'########'"
      class="col-md-6"
      type="text"
      label="Edoc Number:"
      placeholder="XXXXXXXX"
      :disabled="['payroll', 'bursar'].includes(activeCheck.check_identifier)"
      :danger="!!errors.edoc_number"
      :danger-message="errors.edoc_number"
      data-cy="edoc-number"
    >
    </input-box>
    <input-box
      id="orgCode"
      v-model="activeCheck.org_code"
      v-maska="'XXXX'"
      class="col-md-6"
      type="text"
      label="Org Code:"
      placeholder="XXXX"
      :disabled="activeCheck.check_identifier === 'bursar'"
      :danger="!!errors.org_code"
      :danger-message="errors.org_code"
      data-cy="org-code"
    >
    </input-box>
    <input-box
      id="checkNumber"
      v-model="activeCheck.check_number"
      v-maska="'#########'"
      class="col-md-6"
      type="text"
      label="Check Number:"
      placeholder="XXXXXXXXX"
      :danger="!!errors.check_number"
      :danger-message="errors.check_number"
      data-cy="check-number"
    >
    </input-box>
    <input-box
      id="dueDate"
      v-model="activeCheck.due_date"
      class="col-md-6"
      type="date"
      label="Due Date:"
      placeholder="mm/dd/yyyy"
      :danger="!!errors.dueDate"
      :danger-message="errors.dueDate"
      data-cy="due-date"
    >
    </input-box>
    <single-select
      id="instructions"
      v-model="activeCheck.instructions"
      class="col-md-6"
      label="Instructions:"
      :options="instructionsOptions"
      placeholder="Select an option..."
      :danger="!!errors.instructions"
      :danger-message="errors.instructions"
      data-cy="instructions"
    >
    </single-select>
    <input-box
      id="contactName"
      v-model="activeCheck.contact_name"
      class="col-md-6"
      type="text"
      label="Contact Name:"
      placeholder="Wilma Wildcat"
      :danger="!!errors.contact_name"
      :danger-message="errors.contact_name"
      data-cy="contact-name"
    >
    </input-box>
    <input-box
      id="contactNumber"
      v-model="activeCheck.contact_number"
      v-maska="['###-###-####', '###-###-#### ext ####']"
      class="col-md-6"
      type="text"
      label="Contact Number:"
      placeholder="XXX-XXX-XXXX"
      :danger="!!errors.contact_number"
      :danger-message="errors.contact_number"
      data-cy="contact-number"
    >
    </input-box>
    <input-box
      id="contactEmail"
      v-model="activeCheck.contact_email"
      class="col-md-6"
      type="text"
      label="Contact Email:"
      placeholder="wilma@email.arizona.edu"
      :danger="!!errors.contact_email"
      :danger-message="errors.contact_email"
      data-cy="contact-email"
    >
    </input-box>
    <div class="col-md-6">
      <div>Contacted?</div>
      <div class="form-check">
        <input
          id="contacted"
          v-model="activeCheck.contacted"
          class="form-check-input"
          type="checkbox"
        />
        <label class="form-check-label" for="contacted">
          {{ activeCheck.contacted ? "Yes" : "No" }}
        </label>
      </div>
    </div>
    <div class="col-12">
      <alert
        v-if="errors.non_field_errors"
        id="nonFieldErrors"
        type="danger"
        :message="errors.non_field_errors"
      ></alert>
    </div>
    <div class="col-12">
      <alert
        v-if="errors.server"
        id="serverAlert"
        type="danger"
        :message="errors.server"
      ></alert>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { instructionsOptions, checkIdOptions } from "@/utils/constants"
import { InputBox, Alert, SingleSelect } from "@/components/elements"

export default {
  name: "FormCreate",
  components: {
    InputBox,
    Alert,
    SingleSelect,
  },
  data() {
    return {
      instructionsOptions,
      checkIdOptions,
    }
  },
  computed: {
    ...mapGetters("checks", ["activeCheck", "errors"]),
  },
}
</script>
