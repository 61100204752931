<template>
  <div class="d-flex flex-column h-100 mx-2">
    <div class="d-flex justify-content-between align-items-center">
      <div v-if="gridApi" class="d-flex my-1 py-1">
        <icon-button
          id="createButton"
          class="mr-1"
          label="Create"
          icon="cross"
          type="primary"
          data-cy="create-button"
          @click="openNewModal"
        />
        <icon-button
          id="editButton"
          label="Edit"
          class="mr-1"
          icon="pencil"
          type="primary-light"
          :disabled="!selectionExists"
          data-cy="edit-button"
          @click="openEditModal"
        />
        <delete-button
          :grid-api="gridApi"
          :disabled="!selectionExists"
          @input="deleteSelected"
        />
      </div>
      <div class="d-flex mb-2 mb-md-0">
        <input-box
          id="quickFilterBox__home"
          v-model="quickFilter"
          type="text"
          class="my-0"
          placeholder="Quick Search..."
          addon="start"
        >
          <span slot="addon-start" class="input-group-text">
            <icon icon="search" />
          </span>
        </input-box>
        <icon-button
          id="refreshButton"
          class="ml-1"
          icon="refresh-ccw"
          data-cy="refresh-button"
          @click="refetchData"
        />
        <div class="dropdown ml-1">
          <icon-button
            id="exportDropdown"
            label="Export"
            icon="download-cloud"
            class="dropdown-toggle"
            type="primary"
            align-right
            @click="exportMenuOpen = !exportMenuOpen"
          >
          </icon-button>
          <div
            class="dropdown-menu dropdown-menu-right"
            :class="{ show: exportMenuOpen }"
            aria-labelledby="exportDropdown"
          >
            <a
              id="buttonExportModal"
              class="dropdown-item"
              type="button"
              @click="exportData"
            >
              <icon icon="file-x" fixed-width />
              <span class="ml-1">CSV</span>
            </a>
            <a
              id="printableVersion"
              class="dropdown-item"
              type="button"
              @click="printData"
            >
              <icon icon="printer" fixed-width />
              <span class="ml-1">Print</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <advanced-search-bar
      v-if="gridApi"
      :grid-options="gridOptions"
      :filter-fields="filterFields"
    >
      <template slot="toolbar">
        <icon-button
          title="Scroll Back to Top"
          type="primary"
          outline
          class="mr-1"
          icon="arrow-up"
          @click="gridApi.ensureIndexVisible(0, 'top')"
        />

        <icon-button
          id="columnsFilterButton"
          label="Columns"
          type="primary"
          outline
          icon="columns"
          :active="showColumnPanel"
          @click="showColumnPanel = !showColumnPanel"
        />
      </template>
    </advanced-search-bar>

    <div class="flex-bigly-row grid-wrapper">
      <ag-grid-vue
        class="grid grid-flex ag-theme-balham"
        :row-data="unsignedChecks"
        :grid-options="gridOptions"
        @row-double-clicked="openEditModal"
        @grid-ready="onGridReady"
      >
      </ag-grid-vue>
      <columns-panel
        v-if="gridApi"
        v-show="showColumnPanel"
        class="panel"
        :column-api="columnApi"
      >
      </columns-panel>
    </div>

    <!-- Edit Modal -->
    <modal
      v-if="modalVisible.edit"
      id="editModal"
      ref="editModal"
      title="Edit Check Entry"
      size="custom"
      :max-width="900"
      role="dialog"
      @close="modalVisible.edit = false && onModalHidden()"
    >
      <template #default>
        <div class="home-modal">
          <form-edit v-if="modalVisible.edit === true">
            <i class="col-12">{{ lastModifiedBy }}</i>
          </form-edit>
          <comments
            class="home-modal__comments"
            :comments="activeCheck.comments"
          ></comments>
        </div>
      </template>
      <template #footer>
        <icon-button
          id="closeEditModal"
          icon="x"
          label="Cancel"
          outline
          type="accent"
          @click="closeEditModal"
        />
        <icon-button
          id="clearEditModal"
          icon="wind"
          label="Clear"
          type="warning"
          outline
          @click="clearCheckAndComments"
        />
        <icon-button
          id="saveEditModal"
          icon="save"
          label="Save"
          type="primary"
          :disabled="disableSave.edit"
          data-cy="edit-save-button"
          @click="update"
        />
      </template>
    </modal>

    <!-- Multiple Edit Modal -->
    <modal
      v-if="modalVisible.multipleEdit"
      id="multipleEditModal"
      title="Edit Multiple Check Entries"
      hide-footer
      role="dialog"
      @close="modalVisible.multipleEdit = false"
    >
      <template #default>
        <form-multi-edit
          v-if="modalVisible.multipleEdit === true"
          :batch-comment-errors="batchChecks.batchCommentErrors"
          :mod-checks="batchChecks.to_modify"
          :disable-save="disableSave.multipleEdit"
          @close="modalVisible.multipleEdit = false"
          @submit="updateMultipleChecks"
        >
        </form-multi-edit>
      </template>
    </modal>

    <!-- Create/New Modal -->
    <modal
      v-if="modalVisible.new"
      id="createModal"
      title="New Check Entry"
      size="custom"
      :max-width="900"
      role="dialog"
      @close="modalVisible.new = false && onModalHidden()"
    >
      <template #default>
        <div class="home-modal">
          <form-create v-if="modalVisible.new === true"></form-create>
          <comments
            ref="commentComponent"
            class="home-modal__comments"
            prevent-submission
            :comments="activeCheck.comments"
            @addComment="appendCommentToCreate"
          ></comments>
        </div>
      </template>
      <template #footer>
        <icon-button
          id="closeCreateModal"
          icon="x"
          label="Cancel"
          type="accent"
          outline
          @click="closeNewModal"
        />
        <icon-button
          id="clearCreateModal"
          icon="wind"
          label="Clear"
          type="warning"
          outline
          @click="clearCheckAndComments"
        />
        <icon-button
          id="saveAndCopyCreateModal"
          icon="save"
          label="Save and Copy"
          type="primary-light"
          outline
          :disabled="disableSave.new"
          @click="saveAndCopy"
        />
        <icon-button
          id="saveCreateModal"
          icon="save"
          label="Save"
          type="primary"
          :disabled="disableSave.new"
          data-cy="create-save-button"
          @click="save"
        />
      </template>
    </modal>

    <export-modal
      v-if="gridApi"
      :show="modalVisible.export"
      :grid-api="gridApi"
      @close="modalVisible.export = false"
    ></export-modal>

    <toast-group position="top right" width="350px"></toast-group>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue"
import { mapGetters, mapActions } from "vuex"

import AdvancedSearchBar from "@/components/AdvancedSearchBar.vue"
import Comments from "@/components/Comments.vue"
import FormCreate from "@/components/FormCreate.vue"
import ColumnsPanel from "@/components/ColumnsPanel.vue"
import FormEdit from "@/components/FormEdit.vue"
import ExportModal from "@/components/ExportModal.vue"
import FormMultiEdit from "@/components/FormMultiEdit.vue"
import CommentsService from "@/store/services/CommentsService"
import { formatDistanceToNowStrict } from "date-fns"
import useGrid from "@/composables/useGrid"
import {
  Icon,
  IconButton,
  InputBox,
  DeleteButton,
  Modal,
  ToastGroup,
} from "@/components/elements"

export default {
  name: "Home",
  components: {
    AdvancedSearchBar,
    AgGridVue,
    Comments,
    FormCreate,
    ColumnsPanel,
    FormEdit,
    ExportModal,
    FormMultiEdit,
    Icon,
    IconButton,
    InputBox,
    DeleteButton,
    ToastGroup,
    Modal,
  },
  beforeRouteLeave(to, from, next) {
    this.clearCheck()
    this.clearErrors()
    this.clearCommentErrors()
    next()
  },
  setup(_props) {
    const gridConfig = {
      columnDefs: [
        {
          headerName: "CHECK INFO",
          children: [
            { headerName: "Payee Name", field: "payee_name", hide: false },
            { headerName: "Payee #", field: "payee_number", hide: false },
            {
              headerName: "Check Identifier",
              field: "check_identifier",
              hide: false,
              cellRenderer: "checkIdCellRenderer",
            },
            {
              headerName: "Check #",
              field: "check_number",
              hide: false,
            },
            {
              headerName: "Instructions",
              field: "instructions",
              hide: false,
              cellRenderer: "instructionsCellRenderer",
            },
            { headerName: "Edoc #", field: "edoc_number", hide: false },
            { headerName: "Org. Code", field: "org_code", hide: false },
            {
              headerName: "Due Date",
              field: "due_date",
              hide: false,
              cellRenderer: "dateCellRenderer",
            },
          ],
        },
        {
          headerName: "CONTACT INFO",
          children: [
            { headerName: "Contact Name", field: "contact_name" },
            { headerName: "Contact Phone", field: "contact_number" },
            { headerName: "Contact Email", field: "contact_email" },
            {
              headerName: "Contacted?",
              field: "contacted",
              cellRenderer: "booleanCellRenderer",
            },
          ],
        },
        {
          headerName: "Date/Time Created",
          field: "created",
          hide: false,
          cellRenderer: "dateCellRenderer",
        },
        {
          headerName: "Created By",
          field: "user",
          hide: "true",
        },
      ],
    }
    const {
      gridApi,
      columnApi,
      gridOptions,
      quickFilter,
      showColumnPanel,
      selectionExists,
      printGrid,
    } = useGrid(gridConfig) // repetitive but explicit about composition!
    return {
      gridApi,
      columnApi,
      gridOptions,
      quickFilter,
      showColumnPanel,
      selectionExists,
      printGrid,
    }
  },
  data() {
    return {
      exportMenuOpen: false,
      disableSave: {
        new: false,
        edit: false,
        multipleEdit: false,
      },
      modalVisible: {
        new: false,
        edit: false,
        multipleEdit: false,
        export: false,
      },
      batchChecks: {
        to_modify: "",
        batchComment: {
          related_check: "",
          comment: "",
        },
        batchCommentErrors: {
          comment: undefined,
        },
      },
      filterFields: [
        "check_number",
        "check_identifier",
        "contact_email",
        "contact_name",
        "contact_number",
        "contacted",
        "created",
        "edoc_number",
        "instructions",
        "org_code",
        "payee_name",
        "payee_number",
        "user",
        "hasCheckNumber",
      ],
    }
  },
  computed: {
    ...mapGetters("checks", ["unsignedChecks", "activeCheck", "errors"]),
    ...mapGetters("comments", ["activeComment"]),
    lastModifiedBy() {
      const date = new Date(this.activeCheck.modified)
      const relative = formatDistanceToNowStrict(date, { addSuffix: true })
      return `Last modified by ${this.activeCheck.user} ${relative}`
    },
  },

  methods: {
    ...mapActions("checks", [
      "addUnsignedCheck",
      "fetchUnsignedChecks",
      "setCheck",
      "clearCheck",
      "clearErrors",
      "postCheck",
      "putCheck",
      "putChecks",
      "deleteCheck",
      "deleteChecks",
      "updateActiveCheck",
      "updateBlankFields",
      "fetchCheckById",
    ]),
    ...mapActions("comments", {
      clearCommentErrors: "clearErrors",
      postComment: "post",
    }),

    /** Fetch data for the grid */
    async onGridReady({ api }) {
      api.showLoadingOverlay()
      await this.fetchUnsignedChecks()
      api.hideOverlay()
    },

    /** Refetch data in grid and page state */
    refetchData() {
      this.activeCheck.id = ""
      this.clearCheck()
      this.fetchUnsignedChecks()
        .then(() => this.gridApi.redrawRows())
        .catch((error) => {
          this.$toast({
            type: "danger",
            title: "Whoops!",
            message: "Unable to fetch data, please try again.",
            duration: 3000,
          })
          console.error("something went wrong on Refresh:")
          console.error(error)
        })
      // Reset multiple updating
      this.batchChecks.to_modify = ""
      // Reset comments so that next check has no comments associated with it
      this.activeCheck.comments = []
    },

    /** Redraw grid when the modal is hidden */
    onModalHidden() {
      this.gridApi.redrawRows()
      this.activeComment.comment = ""
    },

    openEditModal() {
      const selection = this.gridApi.getSelectedRows()
      if (selection[0].id !== this.activeCheck.id) {
        this.setCheck(selection[0])
        this.activeComment.related_check = this.activeCheck.id
        this.clearErrors()
        this.clearCommentErrors()
      }
      if (selection.length === 1) {
        this.modalVisible.edit = true
      } else {
        this.modalVisible.multipleEdit = true
        let idArray = selection.map((rowObject) => rowObject.id)
        this.batchChecks.to_modify = idArray.toString()
        this.batchChecks.batchCommentErrors.comment = undefined
      }
    },

    closeEditModal() {
      // Restore the items data (useful if it was previously cleared)
      this.setCheck(this.gridApi.getSelectedRows()[0])
      this.clearErrors()
      this.activeComment.comment = ""
      // Hide the modal
      this.modalVisible.edit = false
    },

    openNewModal() {
      // If a row is selected, clear the item before opening the Modal
      if (this.gridApi.getSelectedRows()) {
        // Clear store data
        this.clearCheck()
        this.clearErrors()
        // Unselect the previously selected row in AgGrid
        this.gridApi.deselectAll()
      }
      this.modalVisible.new = true
    },

    closeNewModal() {
      // Clear errrors and reset check when close button is clicked
      this.clearErrors()
      this.clearCheck(true)
      this.activeComment.comment = ""
      // Hide the modal
      this.modalVisible.new = false
    },

    clearCheckAndComments() {
      this.clearCheck(true)
      this.activeComment.comment = ""
    },

    /* API Interactions */
    // TODO: The API 'design' here is poor and overly complex to say the least.
    // Maybe you, Reader, have the time to improve it?

    /** Delete the selected check entry/entries */
    async deleteSelected() {
      const selection = this.gridApi.getSelectedRows()
      if (selection.length === 1) {
        try {
          await this.setCheck(selection[0])
          await this.deleteCheck()
          this.gridApi.redrawRows()
        } catch (error) {
          this.$toast({
            type: "danger",
            title: "Whoops!",
            message: "Unable to delete the check, please try again.",
            duration: 3000,
          })
          console.error("Error: Something went wrong while deleting an item!")
          console.error(error)
        }
      } else {
        let checksString = ""
        selection.forEach((check) => {
          checksString += check.id + ","
        })
        checksString = checksString.replace(/,\s*$/, "")
        try {
          await this.deleteChecks(checksString)
          this.gridApi.redrawRows()
        } catch (error) {
          this.$toast({
            type: "danger",
            title: "Whoops!",
            message: "Unable to delete the selected checks, please try again.",
            duration: 3000,
          })
          console.error(
            "Error: Something went wrong while deleting multiple items!",
          )
          console.error(error)
        }
      }
    },

    /** Save a new check instance to the api */
    async save() {
      // intercept blank value and change it to null
      this.updateBlankFields()
      this.disableSave.new = true
      // Get the comments, if there are any
      let comments = this.fetchComments()
      try {
        const response = await this.postCheck()
        if (response !== null && !Object.keys(this.errors).length) {
          // Post comments, if they exist
          if (comments) {
            await this.postCommentsAfterCreate(comments, response.data.id)
          }
          this.closeNewModal()
          await this.addUnsignedCheck(response.data)
          await this.gridApi.redrawRows()
        }
      } catch (error) {
        this.$toast({
          type: "danger",
          title: "Whoops!",
          message: "Unable to save, please try again.",
          duration: 3000,
        })
        console.error("something went wrong on Save:")
        console.error(error)
      }
      this.disableSave.new = false
    },

    /**
     * Save a new check instance to the api and open a new create modal with some fields copied over
     */
    async saveAndCopy() {
      this.updateBlankFields()
      this.disableSave.new = true
      // Get the comments, if there are any
      let comments = this.fetchComments()
      try {
        const response = await this.postCheck()
        if (response !== null && !Object.keys(this.errors).length) {
          // Post comments, if they exist
          if (comments) {
            await this.postCommentsAfterCreate(comments, response.data.id)
          }
          // Deep copy the item and modify contents
          let savedState = JSON.parse(JSON.stringify(this.activeCheck))
          // Clear everything that we don't want to keep
          savedState.id = undefined
          savedState.check_identifier = undefined
          savedState.payee_name = ""
          savedState.payee_number = ""
          savedState.edoc_number = ""
          savedState.due_date = null
          savedState.org_code = ""
          savedState.instructions = ""
          savedState.check_number = ""
          savedState.contacted = false
          this.setCheck(savedState)
          // Reset comments so that next check has no comments associated with it
          this.activeCheck.comments = []
          // Reset comment submission box text
          this.activeComment.comment = ""
          // Partially refresh the grid
          this.addUnsignedCheck(response.data)
            .then(() => this.gridApi.redrawRows())
            .catch((error) => {
              console.error("something went wrong on Refresh:")
              console.error(error)
            })
          Animation.animateTwice(
            "#createModal .modal .modal-dialog .modal-content",
            "bounceOutDown",
            "bounceInDown",
          )
        }
      } catch (error) {
        this.$toast({
          type: "danger",
          title: "Whoops!",
          message: "Unable to save and copy, please try again.",
          duration: 3000,
        })
        console.error("Something went wrong on Save and Copy:")
        console.error(error)
      }
      this.disableSave.new = false
    },

    /** Update a check */
    async update() {
      // intercept blank value and change it to null
      this.updateBlankFields()
      this.disableSave.edit = true
      try {
        let response = await this.putCheck()
        if (response !== null && !Object.keys(this.errors).length) {
          this.closeEditModal()
          await this.fetchCheckById(this.activeCheck.id)
          await this.updateActiveCheck(this.activeCheck)
          await this.gridApi.redrawRows()
        }
      } catch (error) {
        this.$toast({
          type: "danger",
          title: "Whoops!",
          message: "Unable to update the check, please try again.",
          duration: 3000,
        })
        console.error("something went wrong on Save:")
        console.error(error)
      }
      this.disableSave.edit = false
    },

    /** Update multiple checks */
    async updateMultipleChecks(data) {
      this.disableSave.multipleEdit = true
      // Handle looping through and POSTing new comments first
      await this.appendCommentToCheck(data.comment)
      // Then update the Contacted and Instructions field
      try {
        let response = await this.putChecks(data)
        if (response !== null && !Object.keys(this.errors).length) {
          // If the vuex checks module and the local batchChecks.batchCommentErrors.comment have no errors/content
          if (this.batchChecks.batchCommentErrors.comment === undefined) {
            this.modalVisible.multipleEdit = false
            this.batchChecks.to_modify = ""
            this.refetchData()
          }
        }
      } catch (error) {
        this.$toast({
          type: "danger",
          title: "Whoops!",
          message: "Unable to update the selected check, please try again.",
          duration: 3000,
        })
        console.error("something went wrong on Save:")
        console.error(error)
      }
      this.disableSave.multipleEdit = false
    },

    /* Utility Functions */
    /**
     * When a comment is created for a check that is not yet created, we need to temporarily store it
     * and display it, then post them later. Temporary `created` and `user` properties are added for display
     * purposes, and will be replaced before posting.
     * @param {Object} comment a comment object
     */
    appendCommentToCreate(comment) {
      let newComment = {
        created: Date.now(),
        user: "You",
        ...comment,
      }
      this.activeCheck.comments.push(newComment)
    },

    /**
     * Grab the comments from the activeCheck (via a deep copy) for posting after creation
     * @returns {(Array | undefined)}
     */
    fetchComments() {
      let comments =
        Array.isArray(this.activeCheck.comments) &&
        this.activeCheck.comments.length > 0
          ? JSON.parse(JSON.stringify(this.activeCheck.comments))
          : undefined
      return comments
    },

    /**
     * After creating a new check, post any comments that were added.
     * @param {Array} comments an array of comment objects
     * @param {Number} checkId the check id to post comments for
     * @returns {Promise}
     */
    async postCommentsAfterCreate(comments, checkId) {
      let cleanedComments = comments.map((c) => {
        return {
          comment: c.comment,
          related_check: checkId,
        }
      })
      try {
        for (let comment of cleanedComments) {
          await this.postComment(comment)
        }
      } catch (error) {
        this.$toast({
          type: "danger",
          title: "Whoops!",
          message:
            "Something went wrong posting comments! Please edit the check to add comments.",
        })
        console.error("Something went wrong posting comments:")
        console.error(error)
      }
    },

    /**
     * Append a comment to the check, only used by `updateMultipleChecks`.
     * @param {Object} comment a comment object
     */
    async appendCommentToCheck(comment) {
      // Reset so that correcting the post after failure
      let idArray = this.gridApi
        .getSelectedRows()
        .map((rowObject) => rowObject.id)
      for (var item of idArray) {
        this.batchChecks.batchComment.related_check = item
        this.batchChecks.batchComment.comment = comment
        if (this.batchChecks.batchComment.comment !== undefined) {
          try {
            let response = await CommentsService.create(
              this.batchChecks.batchComment,
            )
            if (response.status != 201) {
              // Break comment when first error is reported by API. Otherwise the same error will repeat for every item in the loop
              break
            }
          } catch (error) {
            this.batchChecks.batchCommentErrors.comment =
              error.response.data.comment[0]
          }
        }
      }
    },
    exportData() {
      this.modalVisible.export = true
      this.exportMenuOpen = false
    },
    printData() {
      this.printGrid()
      this.exportMenuOpen = false
    },
  },
}
</script>

<style lang="postcss">
#quickFilterBox__home {
  @media screen and (max-width: 700px) {
    width: 130px;
  }
}

#multipleWarning {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: 0px;
}

.home-modal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 576px) {
    flex-direction: column;
  }

  .home-modal__comments {
    margin-left: 0.5em;
    /* Ignore modal-content margin */
    margin-right: -15px;
    /* Ignore modal-content y-axis padding */
    margin-top: -15px;
    margin-bottom: -15px;

    @media screen and (max-width: 576px) {
      margin-left: 0;
    }
  }
}

.delete-confirm {
  margin-left: 0.125em;
  margin-right: 0.125em;
  z-index: 10;

  .drop-target {
    height: 100% !important;
  }

  @media screen and (max-width: 600px) {
    .btn {
      i {
        margin-right: 0px !important;
      }

      .btn-label {
        display: none;
      }
    }
  }
}
.card-block {
  padding: 0rem;
}
.comments__header {
  padding-right: 30px;
}

@media print {
  @page {
    size: landscape;
  }
  body {
    zoom: 50%;
    height: auto;
  }
}
</style>
