<template v-slot:default>
  <div class="row">
    <input-box
      id="payeeNumber"
      v-model="activeCheck.payee_number"
      class="col-md-6"
      type="text"
      label="Payee Number:"
      placeholder="XXXXXXX"
      :danger="!!errors.payee_number"
      :danger-message="errors.payee_number"
    >
    </input-box>
    <input-box
      id="payeeName"
      v-model="activeCheck.payee_name"
      class="col-md-6"
      type="text"
      label="Payee Name:"
      placeholder="Wilbur Wildcat"
      :danger="!!errors.payee_name"
      :danger-message="errors.payee_name"
      data-cy="edit-payee-name"
    >
    </input-box>
    <single-select
      id="checkIdentifier"
      v-model="activeCheck.check_identifier"
      class="col-md-6"
      label="Check Identifier:"
      :options="checkIdOptions"
      placeholder="Select an option..."
      :danger="!!errors.check_identifier"
      :danger-message="errors.check_identifier"
    >
    </single-select>
    <input-box
      id="edocNum"
      v-model="activeCheck.edoc_number"
      v-maska="'########'"
      class="col-md-6"
      type="text"
      label="Edoc Number:"
      placeholder="XXXXXXXX"
      :disabled="['payroll', 'bursar'].includes(activeCheck.check_identifier)"
      :danger="!!errors.edoc_number"
      :danger-message="errors.edoc_number"
    >
    </input-box>
    <input-box
      id="orgCode"
      v-model="activeCheck.org_code"
      v-maska="'XXXX'"
      class="col-md-6"
      type="text"
      label="Org Code:"
      placeholder="XXXX"
      :disabled="activeCheck.check_identifier === 'bursar'"
      :danger="!!errors.org_code"
      :danger-message="errors.org_code"
    >
    </input-box>
    <input-box
      id="checkNumber"
      v-model="activeCheck.check_number"
      v-maska="'#########'"
      class="col-md-6"
      type="text"
      label="Check Number:"
      placeholder="XXXXXXXXX"
      :danger="!!errors.check_number"
      :danger-message="errors.check_number"
    >
    </input-box>
    <input-box
      id="dueDate"
      v-model="activeCheck.due_date"
      class="col-md-6"
      type="date"
      label="Due Date:"
      :danger="!!errors.dueDate"
      :danger-message="errors.dueDate"
    >
    </input-box>
    <single-select
      id="instructions"
      v-model="activeCheck.instructions"
      class="col-md-6"
      label="Instructions:"
      :options="instructionsOptions"
      placeholder="Select an option..."
      :danger="!!errors.instructions"
      :danger-message="errors.instructions"
    >
    </single-select>
    <input-box
      id="contactName"
      v-model="activeCheck.contact_name"
      class="col-md-6"
      type="text"
      label="Contact Name:"
      placeholder="Wilma Wildcat"
      :danger="!!errors.contact_name"
      :danger-message="errors.contact_name"
    >
    </input-box>
    <input-box
      id="contactNumber"
      v-model="activeCheck.contact_number"
      v-maska="['###-###-####', '###-###-#### ext ####']"
      class="col-md-6"
      type="text"
      label="Contact Number:"
      placeholder="XXX-XXX-XXXX"
      :danger="!!errors.contact_number"
      :danger-message="errors.contact_number"
    >
    </input-box>
    <input-box
      id="contactEmail"
      v-model="activeCheck.contact_email"
      class="col-md-6"
      type="text"
      label="Contact Email:"
      placeholder="wilma@email.arizona.edu"
      :danger="!!errors.contact_email"
      :danger-message="errors.contact_email"
    >
    </input-box>
    <div class="form-group col-md-6">
      <div>Contacted?</div>
      <div class="form-check">
        <input
          id="contacted"
          v-model="activeCheck.contacted"
          class="form-check-input"
          type="checkbox"
        />
        <label class="form-check-label" for="contacted">
          {{ activeCheck.contacted ? "Yes" : "No" }}
        </label>
      </div>
    </div>
    <div class="col-12">
      <alert
        v-if="errors.non_field_errors"
        id="serverAlert_NONFIELD"
        type="danger"
        :message="errors.non_field_errors"
      >
      </alert>
    </div>
    <div class="col-12">
      <alert
        v-if="errors.server"
        id="serverAlert_EDIT"
        type="danger"
        :message="errors.server"
      >
      </alert>
    </div>
    <div
      v-if="
        activeCheck.archived_signatures != null &&
        activeCheck.archived_signatures.length
      "
      class="col-12"
    >
      <archived-signatures
        :archived-signatures="activeCheck.archived_signatures"
      />
    </div>
    <!-- Slot for the "last modified by..." message -->
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { instructionsOptions, checkIdOptions } from "@/utils/constants"
import { InputBox, Alert, SingleSelect } from "@/components/elements"
import ArchivedSignatures from "@/components/ArchivedSignatures.vue"

export default {
  name: "FormEdit",
  components: {
    InputBox,
    Alert,
    SingleSelect,
    ArchivedSignatures,
  },
  data() {
    return {
      instructionsOptions,
      checkIdOptions,
      archiveCollapsedState: true,
    }
  },
  computed: {
    ...mapGetters("checks", ["activeCheck", "errors"]),
  },
}
</script>
